require('@rails/ujs').start();

const flash = document.getElementById('flash-messages');
if (flash && flash.dataset && flash.dataset.success) {
  var toastEl = document.getElementById('toast-el');
  document.getElementById('toast-message').innerHTML = flash.dataset.success;
  document.getElementById('toast-title').innerHTML = 'Enquiry sent';
  var toast = new bootstrap.Toast(toastEl);
  toast.show();
}
